import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';
const parser = new DOMParser();

// Default center location (Lincoln, NE)
const DEFAULT_CENTER = {
  lat: 40.8137,
  lng: -96.7026,
};

// SVG for the pin
const generatePinSvg = (backgroundColor, iconColor) => `
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
      <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
      <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
      <feColorMatrix result="colorOut" in="blurOut" type="matrix"
        values="0 0 0 0 0
                0 0 0 0 0
                0 0 0 0 0
                0 0 0 0.2 0" />
      <feBlend in="SourceGraphic" in2="colorOut" mode="normal" />
    </filter>
  </defs>
  <rect width="32" height="32" rx="16" fill="${backgroundColor}" filter="url(#dropShadow)" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4325 24.8042L15.4362 24.8079L15.4386 24.8104C15.6002 24.9328 15.7972 24.999 15.9999 24.999C16.2026 24.999 16.3996 24.9328 16.5612 24.8104L16.5636 24.8079L16.5673 24.8042L16.5821 24.7932C16.6614 24.7321 16.7394 24.6693 16.8159 24.6048C17.7337 23.8313 18.5768 22.9733 19.3342 22.0422C20.6894 20.3658 22.1541 17.9288 22.1541 15.1532C22.1541 13.521 21.5057 11.9557 20.3516 10.8015C19.1974 9.64741 17.6321 8.99902 15.9999 8.99902C14.3677 8.99902 12.8024 9.64741 11.6482 10.8015C10.4941 11.9557 9.8457 13.521 9.8457 15.1532C9.8457 17.9288 11.3104 20.3658 12.6668 22.0422C13.4242 22.9733 14.2674 23.8313 15.1851 24.6048C15.2618 24.6687 15.3393 24.7314 15.4177 24.7932L15.4325 24.8055V24.8042ZM15.9999 16.9995C16.2424 16.9995 16.4824 16.9517 16.7064 16.8589C16.9304 16.7662 17.134 16.6302 17.3054 16.4587C17.4768 16.2873 17.6128 16.0838 17.7056 15.8598C17.7984 15.6358 17.8462 15.3957 17.8462 15.1532C17.8462 14.9108 17.7984 14.6707 17.7056 14.4467C17.6128 14.2227 17.4768 14.0192 17.3054 13.8477C17.134 13.6763 16.9304 13.5403 16.7064 13.4475C16.4824 13.3547 16.2424 13.307 15.9999 13.307C15.5102 13.307 15.0406 13.5015 14.6944 13.8477C14.3482 14.194 14.1536 14.6636 14.1536 15.1532C14.1536 15.6429 14.3482 16.1125 14.6944 16.4587C15.0406 16.805 15.5102 16.9995 15.9999 16.9995Z" fill="${iconColor}" />
</svg>
`;

const buildInfoWindow = ({ title, subtitle, href, photo }) => {
  return `
    <a href="${href}" target="_blank" data-turbo-frame="_top">
      <div class="font-rubik bg-primary overflow-hidden transition-all gap-3 w-56">
        <img class="w-full relative object-cover object-top aspect-[4/3.5] h-40" src="${photo}" />
        <div class="text-primary px-3 py-2">
          <h3 class="font-medium text-sm truncate block line-clamp-1">${title}</h3>
          <p class="text-xs truncate block line-clamp-1">${subtitle}</p>
        </div>
      </div>
    </a>
  `;
};

export default class extends Controller {
  static targets = [
    'map',
    'project',
    'zoomInButton',
    'zoomOutButton',
    'noProjectsMessage',
    'projectCount',
  ];
  static values = {
    apiKey: String,
    mapId: String,
    city: String,
    state: String,
  };

  activeMarker = null;
  mapInteracted = false;
  markers = new Map();

  async connect() {
    // Hide no projects message by default - it should only show after map interaction
    const noProjectsMessage = this.noProjectsMessageTarget;
    if (noProjectsMessage) {
      noProjectsMessage.style.display = 'none';
    }

    this.initLoader();
    await this.buildMap();
    await this.addMarkers();
    this.setupHoverListeners();
    this.syncListToMap();
  }

  setupHoverListeners() {
    this.projectTargets.forEach((project) => {
      const projectId = project.dataset.id;
      const marker = this.markers.get(projectId);

      if (!marker) return;

      project.addEventListener('mouseenter', () => {
        this.activateMarker(marker);
      });

      project.addEventListener('mouseleave', () => {
        if (this.infoWindow.getMap()) return; // Don't deactivate if info window is open
        this.resetActiveMarker();
      });
    });
  }

  activateMarker(marker) {
    this.resetActiveMarker();

    const activePin = parser.parseFromString(
      generatePinSvg('#27272A', '#ffffff'),
      'image/svg+xml',
    ).documentElement;

    marker.content.replaceWith(activePin);
    marker.content = activePin;
    this.activeMarker = marker;
  }

  initLoader() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly',
    });
  }

  async buildMap() {
    const { Map, InfoWindow } = await this.loader.importLibrary('maps');
    this.infoWindow = new InfoWindow();

    this.map = new Map(this.mapTarget, {
      center: DEFAULT_CENTER,
      zoom: 12,
      maxZoom: 15,
      mapId: this.mapIdValue,
      disableDefaultUI: true,
      clickableIcons: false,
      gestureHandling: 'greedy',
      zoomControl: false,
    });

    this.centerMapToMarkers();

    // Track map interactions
    ['dragstart', 'zoom_changed'].forEach((event) => {
      this.map.addListener(event, () => {
        this.mapInteracted = true;
      });
    });

    this.map.addListener('click', () => {
      this.mapInteracted = true;
      this.infoWindow.close();
      this.resetActiveMarker();
    });

    // Add listeners for map movement and zoom
    this.map.addListener('dragend', () => this.syncListToMap());
    this.map.addListener('zoom_changed', () => this.syncListToMap());

    this.infoWindow.addListener('closeclick', () => {
      this.resetActiveMarker();
    });
  }

  zoomIn() {
    const currentZoom = this.map.getZoom();
    const maxZoom = 15;
    if (currentZoom < maxZoom) {
      this.map.setZoom(currentZoom + 1);
      this.syncListToMap();
    }
    this.updateZoomButtons();
  }

  zoomOut() {
    const currentZoom = this.map.getZoom();
    const minZoom = 8;
    if (currentZoom > minZoom) {
      this.map.setZoom(currentZoom - 1);
      this.syncListToMap();
    }
    this.updateZoomButtons();
  }

  updateZoomButtons() {
    const currentZoom = this.map.getZoom();
    const maxZoom = 15;
    const minZoom = 8;

    if (this.hasZoomInButtonTarget) {
      this.zoomInButtonTarget.disabled = currentZoom >= maxZoom;
      this.zoomInButtonTarget.classList.toggle(
        'brightness-75',
        currentZoom >= maxZoom,
      );
      this.zoomInButtonTarget.classList.toggle(
        'cursor-not-allowed',
        currentZoom >= maxZoom,
      );
    }

    if (this.hasZoomOutButtonTarget) {
      this.zoomOutButtonTarget.disabled = currentZoom <= minZoom;
      this.zoomOutButtonTarget.classList.toggle(
        'brightness-75',
        currentZoom <= minZoom,
      );
      this.zoomOutButtonTarget.classList.toggle(
        'cursor-not-allowed',
        currentZoom <= minZoom,
      );
    }
  }

  syncListToMap() {
    const bounds = this.map?.getBounds();
    if (!bounds) return;

    let visibleCount = 0;

    this.projectTargets.forEach((project) => {
      const lat = parseFloat(project.dataset.latitude);
      const lng = parseFloat(project.dataset.longitude);

      if (isNaN(lat) || isNaN(lng)) {
        project.style.display = 'none';
        return;
      }

      const isVisible = bounds.contains({ lat, lng });
      project.style.display = isVisible ? 'block' : 'none';
      if (isVisible) visibleCount++;
    });

    if (this.hasProjectCountTarget) {
      this.projectCountTarget.style.display =
        visibleCount > 0 ? 'block' : 'none';
      this.projectCountTarget.textContent = `${visibleCount} ${
        visibleCount === 1
          ? 'project in the map area'
          : 'projects in the map area'
      }`;
    }

    if (this.mapInteracted) {
      this.noProjectsMessageTarget.style.display =
        visibleCount > 0 ? 'none' : 'block';
    }

    this.updateZoomButtons();
  }

  showAllProjects() {
    this.projectTargets.forEach((project) => {
      project.style.display = 'block';
    });
  }

  centerMapToUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.map.setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.syncListToMap();
      }, this.centerMapToMarkers.bind(this));
    } else {
      this.centerMapToCompanyCity();
    }
  }

  async centerMapToMarkers() {
    const { LatLngBounds, LatLng } = await this.loader.importLibrary('core');

    const validCoordinates = this.projectTargets
      .map((project) => {
        const lat = parseFloat(project.dataset.latitude);
        const lng = parseFloat(project.dataset.longitude);
        if (isNaN(lat) || isNaN(lng)) return null;
        if (lat === 0 && lng === 0) return null;
        return new LatLng(lat, lng);
      })
      .filter((coord) => coord !== null);

    if (validCoordinates.length === 0) {
      this.centerMapToCompanyCity();
      return;
    }

    const bounds = validCoordinates.reduce((bounds, coord) => {
      bounds.extend(coord);
      return bounds;
    }, new LatLngBounds());

    const padding = { top: 100, right: 100, bottom: 100, left: 100 };
    this.map.fitBounds(bounds, padding);
    this.syncListToMap();
  }

  async centerMapToCompanyCity() {
    const result = await this.geocodeAddress(
      `${this.cityValue}, ${this.stateValue}`,
    );
    if (result.results.length) {
      const geometry = result.results[0].geometry;
      const center = geometry.location;
      this.map.setCenter(center);
      this.syncListToMap();
    }
  }

  resetActiveMarker() {
    if (this.activeMarker) {
      const defaultPin = parser.parseFromString(
        generatePinSvg('#ffffff', '#27272A'),
        'image/svg+xml',
      ).documentElement;
      this.activeMarker.content.replaceWith(defaultPin);
      this.activeMarker.content = defaultPin;
      this.activeMarker = null;
    }
  }

  async addMarkers() {
    const { AdvancedMarkerElement } = await this.loader.importLibrary('marker');

    this.projectTargets.forEach((project) => {
      const { id, latitude, longitude, title, createdAt, href, featurephoto } =
        project.dataset;

      if (!latitude || !longitude) return;

      const marker = this.addMarker({
        infoWindowContent: buildInfoWindow({
          title,
          subtitle: this.formatDate(createdAt),
          href,
          photo: featurephoto,
        }),
        AdvancedMarkerElement,
        latitude,
        longitude,
      });

      this.markers.set(id, marker);
    });

    this.syncListToMap();
  }

  addMarker({ AdvancedMarkerElement, latitude, longitude, infoWindowContent }) {
    const defaultPin = parser.parseFromString(
      generatePinSvg('#ffffff', '#27272A'),
      'image/svg+xml',
    ).documentElement;

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
      content: defaultPin,
      gmpClickable: true,
    });

    marker.addListener('click', () => {
      this.activateMarker(marker);
      this.infoWindow.setContent(infoWindowContent);
      this.infoWindow.open(marker.map, marker);
    });

    return marker;
  }

  formatDate(dateString) {
    if (!dateString) return 'Unknown date';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  async geocodeAddress(address) {
    const { Geocoder } = await this.loader.importLibrary('geocoding');
    const geocoder = new Geocoder();
    const result = await geocoder.geocode({ address: address });

    return result;
  }
}
